body {
  background-color: #2a5ca6 !important;
}

h1 {
  font-size: 4em !important;
}

.top-container {
  background-color: #2a5ca6;
  padding: 0px;
  margin: 0px;
  overflow: hidden;
}

.bigger {
  flex-grow: 3;
}

.landing-image {
  width: 200px;
  height: auto;
}

.hero-section {
  padding: 20px;
  padding-top: 0px;
  margin-top: 10px;
  justify-content: center;
}

.hero-section-light {
  padding: 20px;
  padding-top: 20px;
  margin-top: 10px;
  justify-content: center;
  background-color: #eeeef1;
}

.zip-input {
  width: 100px;
  height: 44px;
  border-radius: 5px;
  border: none;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.button-check {
  height: fit-content;
  width: 120px;
  min-width: 120px;
  text-align: center;
  background-color: #113b79;
  margin-left: 5px;
  margin-top: 10px;
  padding: 10px;
  color: #eeeef1;
}

.button-check:hover {
  background-color: #6f9de1;
}

.downloads {
  padding: 20px;
}

.download-apple {
  width: 200px;
  height: auto;
}

.download-android {
  width: 250px;
  height: auto;
}

.download-box {
  padding: 20px;
  padding-bottom: 0px;
}

.hero-box {
  padding: 20px;
  max-width: 600px;
  min-width: 300px;
}

.hero-image {
  height: auto;
}

.highlighted {
  background-color: yellow;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.main-text {
  color: #eeeef1;
}

.light-text {
  color: #464d54;
}

.headline-size {
  font-size: 8vw;
}

.headline-main {
  color: #eeeef1;
}

.divider-line {
  color: #eeeef1;
}

.divider-line-gray {
  color: #464d54;
}
