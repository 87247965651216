.footer-section {
  background-color: #464d54;
  padding: 20px;
}

.footer-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #eeeef1;
  background-color: #2a5ca6;
  padding: 20px;
}

.footer-link {
  color: #eeeef1;
  text-decoration: none;
}

.footer-link:hover {
  color: #ffffff;
}

.center-this {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.veteran-image {
  width: 240px;
}

.veteran-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
