.video-admin-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: #2a5ca6;
}

.video-admin-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.video-admin-logo {
  width: 200px;
  height: auto;
}
