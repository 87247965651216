.admin-header {
  padding-top: 20px;
  padding-right: 10px;
  padding-left: 20px;
}

.admin-logo {
  width: 200px;
  height: auto;
}
