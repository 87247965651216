.signup-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.signup-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #eeeef1;
  width: 90%;
  border-radius: 10px;
  padding: 10px;
  padding-right: 0px;
}

.center-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 40px;
}

.middle-row {
  max-width: 600px;
  justify-content: center;
}

.title-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.title-text {
  font-size: 2rem;
  font-weight: 500;
  color: #113b79;
  margin-bottom: 0px;
  text-align: center;
}

.generic-center {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subtitle-text {
  font-size: 1.2rem;
  color: #464d54;
  margin-bottom: 20px;
  text-align: center;
}

.bottom-row {
  padding-left: 40px;
  padding-right: 40px;
}

.bottom-text {
  font-size: 1.2rem;
  color: #464d54;
  text-align: center;
}

.form-style {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  padding: 10px;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

.form-column {
  display: flex;
  flex-direction: column;
  width: 95%;
  padding-right: 0px;
}

.form-label {
  font-size: 1.1rem;
  color: #464d54;
  margin-bottom: 0px;
  margin-left: 5px;
}

.form-input {
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  border-radius: 10px;
  border: 2px solid #bbbec6;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #464d54;
}

.form-ready {
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  border-radius: 10px;
  border: 2px solid #bbbec6;
  background-color: palegreen;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #464d54;
}

.form-error {
  width: 100%;
  padding: 10px;
  padding-left: 15px;
  border-radius: 10px;
  border: 2px solid #bbbec6;
  border-color: red;
  margin-bottom: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  color: #464d54;
}

.button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-right: 10px;
  margin: 0px;
}

.submit-button {
  width: 95%;
  height: 50px;
  background-color: #029d07;
  outline-style: solid;
  outline-width: 2px;
  outline-color: #fff;
  color: #eeeef1;
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 20px;
}

.submit-button:hover {
  background-color: limegreen;
}

.boop {
  background-color: aqua;
}
