.start-section {
  display: flex;
  justify-content: center;
  background-color: #eeeef1;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 0px;
  padding-bottom: 40px;
}

.start-block {
  min-width: 100%;
  width: 100%;
  height: 70px;
  background-color: #029d07;
  outline-style: solid;
  outline-width: 5px;
  outline-color: #1edd62;
  color: #eeeef1;
  font-size: 1.5rem;
  font-weight: bold;
}

.button-block:hover {
  background-color: #2a5ca6;
  color: #ffffff;
}

.center-this {
  text-align: center;
}

.pointer {
  cursor: pointer;
}

.highlighted {
  background-color: yellow;
}

.center-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.offer-text {
  color: #464d54;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
}
