body {
  background-color: #2a5ca6 !important;
}

.video-container {
  background-color: #eeeef1;
}

.bigger {
  flex-grow: 3;
}

.padded-row {
  padding: 20px;
  padding-bottom: 0px;
}

.header-section {
  padding: 20px;
  padding-bottom: 0px;
}

.video-title {
  color: #113b79;
  text-align: center;
}

.video-subtitle {
  color: #eeeef1;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 200;
}

.landing-image {
  width: 200px;
  height: auto;
}

.hero-section {
  padding: 20px;
}

.title-font {
  color: #eeeef1;
  text-align: center;
  font-weight: bold;
}

.subtitle-font {
  color: #eeeef1 !important;
  text-align: center !important;
}

.zip-input {
  width: 100px;
  height: 44px;
  border-radius: 5px;
  border: none;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.button-zip {
  width: 120px;
  text-align: center;
  background-color: #113b79;
  margin-left: 10px;
  margin-top: 10px;
  padding: 8px;
  color: #eeeef1;
  font-size: 1.3rem;
}

.button-zip:hover {
  background-color: #6f9de1;
}

.downloads {
  padding: 20px;
}

.download-apple-landing {
  width: 200px;
  height: auto;
}

.download-android-landing {
  width: 250px;
  height: auto;
}

.download-box-landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-box {
  padding: 20px;
}

.hero-image {
  height: auto;
}

.highlighted {
  background-color: yellow;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.example-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.example-image {
  max-width: 500px;
  height: auto;
}

.video-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-col {
  max-width: 800px;
  padding: 20px;
}

.react-player {
  position: relative;
  top: 0;
  left: 0;
}

.signup-button {
  background-color: #113b79;
  color: #eeeef1;
  padding: 10px;
  border-radius: 5px;
  font-size: 1.3rem;
}

.nopage-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
}

.nopage-title {
  color: #eeeef1;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
}

.nopage-text {
  color: #eeeef1;
  font-size: 1.5rem;
  font-weight: 300;
  text-align: center;
}

.nopage-subtext {
  color: #eeeef1;
  font-size: 1.5rem;
  font-weight: 200;
  text-align: center;
}

.nopage-subtext-bottom {
  margin-top: 20px;
}

.thanks-col {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 100vh;
  padding-top: 40px;
  padding-left: 30px;
  padding-right: 30px;
}

.thanks-main-title {
  color: #eeeef1;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.thanks-image {
  max-width: 600px;
  height: auto;
}

.thanks-subtext {
  color: #eeeef1;
  font-size: 1.1rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 40px;
}

.thanks-link {
  color: #eeeef1;
  font-size: 1.4rem;
  font-weight: 200;
}

.thanks-link:hover {
  color: #6f9de1;
}
