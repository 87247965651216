.scan-column {
  display: flex;
  background-color: #2a5ca6;
  max-width: 430px;
  color: #eeeef1;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 0px;
  margin: 0px;
}

.scan-center {
  display: flex;
  justify-content: center;
}

.download-scan-android {
  width: 260px;
  height: auto;
}

.download-scan-apple {
  width: 230px;
  height: auto;
}

.main-text-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}
