body {
  background-color: #2a5ca6 !important;
}

.video-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #eeeef1;
}

.video-main-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-main-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  padding: 20px;
}

.bigger {
  flex-grow: 3;
}

.padded-row {
  padding: 20px;
  padding-bottom: 0px;
}

.header-section {
  padding: 20px;
  padding-bottom: 0px;
}

.video-title {
  color: #113b79;
  text-align: center;
  font-size: 2rem;
}

.super-title-row {
  padding: 10px;
  background-color: #00703e;
  margin-bottom: 15px;
  margin-left: 0px;
  margin-right: 0px;
  min-width: 100%;
}

.super-title {
  color: #fff;
  text-align: center;
  font-size: 1.7rem;
  margin-bottom: 0px;
}

.super-max-row {
  padding-top: 10px;
}

.super-max-title {
  color: #113b79;
  text-align: center;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.super-max-title {
  color: #113b79;
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  margin-bottom: 15px;
}

.video-subtitle {
  color: #113b79;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 300;
}

.landing-image {
  width: 200px;
  height: auto;
}

.hero-section {
  padding: 20px;
  padding-top: 0px;
}

.title-font {
  color: #eeeef1;
  text-align: center;
  font-weight: bold;
}

.subtitle-font {
  color: #eeeef1 !important;
  text-align: center !important;
}

.zip-input {
  width: 100px;
  height: 44px;
  border-radius: 5px;
  border: none;
  padding: 10px;
  font-size: 20px;
  text-align: center;
  margin-top: 10px;
}

.button-zip {
  width: 120px;
  text-align: center;
  background-color: #113b79;
  margin-left: 10px;
  margin-top: 10px;
  padding: 8px;
  color: #eeeef1;
  font-size: 1.3rem;
}

.button-zip:hover {
  background-color: #6f9de1;
}

.downloads {
  padding: 20px;
}

.download-apple-landing {
  width: 200px;
  height: auto;
}

.download-android-landing {
  width: 250px;
  height: auto;
}

.download-box-landing {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.hero-box {
  padding: 20px;
}

.hero-image {
  height: auto;
}

.highlighted {
  background-color: yellow;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.example-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.example-image {
  max-width: 500px;
  height: auto;
}

.video-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.video-signup-button {
  background-color: green;
  color: #fff;
  border-color: #1edd62;
  border-width: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 500;
}

.video-signup-button:hover {
  background-color: limegreen;
  color: #fff;
}

.explainer-row {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  margin: 20px;
  width: 100%;
}

.explainer-col-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  min-width: 360px;
}

.explainer-col-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #2a5ca6;
  padding: 25px;
  padding-bottom: 30px;
  min-width: 360px;
}

.guarantee-row {
  margin-bottom: 20px;
}

.guarantee-image {
  max-width: 300px;
  height: auto;
}

.explainer-title {
  color: #113b79;
  font-size: 1.3rem;
  margin-bottom: 0px;
}

.explainer-text {
  color: #113b79;
  font-size: 1.2rem;
  font-weight: 300;
  margin-bottom: 30px;
}

.explainer-text-last {
  color: #113b79;
  font-size: 1.2rem;
  font-weight: 300;
}

.explainer-signup-title {
  color: #fff;
  font-size: 1.5rem;
  font-weight: 400;
  margin-bottom: 5px;
}

.explainer-signup-sub-title {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  margin-bottom: 5px;
}

.explainer-signup-subtext {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.4;
}

.explainer-signup-microtext {
  color: #fff;
  font-size: 1.1rem;
  font-weight: 200;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1.4;
}

.signup-button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 15px;
}

.launch-signup-subtext {
  color: #fff;
  font-size: 1.2rem;
  font-weight: 200;
  text-align: center;
  line-height: 1.4;
  margin-bottom: 0px;
}

.offer-big-top {
  margin-top: 40px;
}

.offer-med-top {
  margin-top: 20px;
}

.offer-signup-title {
  color: #fff;
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 5px;
}

.video-reject-button {
  background-color: gray;
  color: #fff;
  border-color: lightgray;
  border-width: 3px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: 50px;
  padding-right: 50px;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 400;
}

.video-reject-button:hover {
  background-color: darkgray;
  color: #fff;
}

.founders-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.founders-image {
  max-width: 400px;
  height: auto;
}
